import React, { useRef } from 'react';

const Navbar: React.FC = () => {
  return (
    <nav className="bg-blue-600 p-4 shadow-lg">
      <div className="container mx-auto flex  items-center">
        <ul className="flex space-x-20">
          <li><a href="/" className="text-white hover:underline">Home</a></li>
          <li><a href="/describetext" className="text-white hover:underline">Describe Text</a></li>
          <li><a href="/describefile" className="text-white hover:underline">Describe File</a></li>
          <li><a href="/converttext" className="text-white hover:underline">Convert Text</a></li>
          <li><a href="/convertfile" className="text-white hover:underline">Convert File</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
