export const handleUpload = async (uploadUrl: string, fileName: string) => {
    const response = await fetch(uploadUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    
    URL.revokeObjectURL(url);
}


export const handleClick = async (files: FileList | null, selectedOptions: string[]| null, url: string) => {
    if (files) {
      const formData = new FormData();

      Array.from(files).forEach(file => {
        formData.append('files', file);
      });

      if (selectedOptions !== null)  formData.append('options', JSON.stringify(selectedOptions));
      
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();
        //setUploadUrl(data.path)
       return  data.path
      } catch (error) {
        //setResponseMessage('Une erreur est survenue.');
        throw new Error("erreur")
        console.error(error);
      } finally {
        //setIsLoading(false); // Hide loading indicator
      }
    } else {
      console.log('Aucun fichier sélectionné');
      // setResponseMessage('Veuillez sélectionner un fichier.');
    }
  };

export const sendT = async (url: string, texts: string[]) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( {
        text: texts,
      }),
    });
    return response
  }