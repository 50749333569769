import React, { useState } from 'react';
import { options } from '../config';

const ConvertFile: React.FC = () => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [uploadUrl, setUploadUrl] = useState<string>();
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles(selectedFiles);
    }
  };


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOptions((prev) =>
      prev.includes(value) ? prev.filter((opt) => opt !== value) : [...prev, value]
    );
  };

  const handleUpload = async () => {
    const fileUrl = uploadUrl
    if (fileUrl) {
    const response = await fetch("http://gopiko.fr:8780/upload/"+fileUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement("a");
    a.href = url;
    a.download = "filename";
    a.click();
    
    URL.revokeObjectURL(url);
    }
  }

  const handleClick = async () => {
    if (files) {
      const formData = new FormData();

      Array.from(files).forEach(file => {
        formData.append('files', file);
      });

      formData.append('options', JSON.stringify(selectedOptions));


      setIsLoading(true);

      try {
        const response = await fetch('http://gopiko.fr:8780/convert/file', {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();
        console.log(data)
        setUploadUrl(data.path)
       
      } catch (error) {
        setResponseMessage('Une erreur est survenue.');
        console.error(error);
      } finally {
        setIsLoading(false); // Hide loading indicator
      }
    } else {
      console.log('Aucun fichier sélectionné');
      setResponseMessage('Veuillez sélectionner un fichier.');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-3xl mb-4">Hello World!</h1>
        {/* File Input */}
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="mb-4 p-2 border rounded"
        />
        
        <div className="mb-4 text-left">
        {options.map((option) => (
    <label key={option} className="block">
      <input
        type="checkbox"
        value={option}
        onChange={handleCheckboxChange}
        checked={selectedOptions.includes(option)}
        className="mr-2"
      />
      {option.charAt(0).toUpperCase() + option.slice(1)} {/* Capitalisation */}
    </label>
  ))}
        </div>

        {/* Submit Button */}
        <button
          onClick={handleClick}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Envoi en cours...' : 'Envoyer les fichiers'}
        </button>

          {/* Submit Button */}
          {uploadUrl && (
  <div>
    <button
      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      onClick={handleUpload}
    >
      Download file
    </button>
  </div>
)}
        {/* Response Message */}
        {responseMessage && <p className="mt-4 text-xl">{responseMessage}</p>}
      </div>
    </div>
  );
};

export default ConvertFile;
