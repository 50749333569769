import React, { useRef } from 'react';
import Navbar from "./components/Navbar"; // Assure-toi que ce fichier existe
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ConvertFile from './Convert/convertFile'; // Assure-toi que ce fichier existe
import Home from './home';
import ConvertText from './Convert/convetText';
import DescribeFile from './describe/describeFile';
import DescribeText from './describe/describeText';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Navbar /> {/* Affichage de la barre de navigation */}
        <Routes>
          <Route path="/" element={<Home />} /> {/* Route d'accueil */}
          <Route path="/convertfile" element={<ConvertFile />} /> {/* Route vers la page de conversion */}
          <Route path="/converttext" element={<ConvertText />} />
          <Route path="/describefile" element={<DescribeFile />} /> {/* Route vers la page de conversion */}
          <Route path="/describetext" element={<DescribeText />} />
          <Route path="*" element={<h1>Page non trouvée</h1>} />

        </Routes>
        
      </div>
    </Router>
  );
};

export default App;