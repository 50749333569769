import React, { useState } from 'react';
import { handleClick } from '../utils/downloader';

const DescribeFile: React.FC = () => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles(selectedFiles);
    }
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="mb-4 p-2 border rounded"
        />
        <button
          onClick={async () => {
            setIsLoading(true);
            try {
            await handleClick(files, null ,'http://gopiko.fr:8780/describe/file')
            } catch (err) {
              setResponseMessage('Une erreur est survenue.');
            }
            setIsLoading(false);
          }}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          disabled={isLoading}
        >
          {isLoading ? 'Envoi en cours...' : 'Envoyer les fichiers'}
        </button>
        {responseMessage && <p className="mt-4 text-xl">{responseMessage}</p>}
      </div>
    </div>
  );
};

export default DescribeFile;