import React, { useState } from 'react';
import { isTemplateExpression } from 'typescript';

interface TextItem {
  text: string;
  types: string;
}


const sendT = async (texts: string[]) => {
  const response = await fetch('http://gopiko.fr:8780/describe/text', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify( {
      text: texts, // Wrap the array in an object with the "text" key
    }), // Convert texts to JSON string
  });
  console.log(response.body)
  return response
}

const DescribeText: React.FC = () => {
  const [texts, setTexts] = useState<TextItem[]>([
    { text: '', types: '' },
  ]);

  const sendOnTxt = async (index: number) => {
    try {
      const textToSend = texts[index].text;
      console.log("Sending:", textToSend);
  
      const response = await sendT([textToSend]); // Ensure this returns a proper response
      if (!response.ok) throw new Error("API Error");
  
      const jsonData = await response.json();
      const convertedText = jsonData[0]?.Data?.ct_label || "No Data"; // Safe access
  
      console.log("Received:", convertedText);
  
      // ✅ Update the state correctly
      setTexts((prevTexts) =>
        prevTexts.map((item, indexCurrent) =>
          indexCurrent === index ? { ...item, types: convertedText } : item
        )
      );
  
    } catch (error) {
      console.error("Error sending text:", error);
    }
  };
  

  
  const  extraireItem1 = (tableauItems: TextItem[]): string[] =>  {
    return tableauItems.map((item) => item.text);
  }

  const addText = () => {
    setTexts([...texts, { text: '', types: '' }]);
  };

  const resetConvertedText = () => {
    setTexts((prevTexts) =>
      prevTexts.map((item) => ({ ...item, convertedText: [] }))
    );
  };

  function extractDigitToInt(fileName: string): number | null {
    const match = fileName.match(/^(\d+)\./);
    if (match && match[1]) {
      return parseInt(match[1], 10);
    }
    return null;
  }  

  const sendAllText = async () => {
    const b = extraireItem1(texts)
    const response = await sendT(b)
    console.log("resp = ", response)
    const jsonData = await response.json();
    console.log("hoho =) ", jsonData)
    resetConvertedText()
    
    jsonData.forEach((element:any) => {
        console.log(element)
        const d =element.index
        if (d !== null)
          setTexts((prevTexts) =>
          prevTexts.map((item, indexCurrent) =>
            indexCurrent === d ? { ...item, types: element?.Data?.ct_label } : item
          )
        );
    });
    console.log("hoho", texts)
  }

  const removeText = (indexToRemove: number) => {
    setTexts((prevTexts) => prevTexts.filter((_, index) => index !== indexToRemove));
  };

  const handleTextChange = (index: number, newText: string) => {
    setTexts((prevTexts) =>
      prevTexts.map((item, indexCurrent) =>
        indexCurrent === index ? { ...item, text: newText } : item
      )
    );
  };

  const handleTextConvertedChange = (index: number, newText: string) => {

  };

  return (
    <>
                    <button
                  className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  onClick={async () =>  await sendAllText()}
                >
                  Convert Text
                </button>
      {texts.map((item, index) => (
        <div key={index} className="w-full mb-4">
          <div className="grid grid-cols-2 w-full">
            {/* Left Section */}
            <div className="bg-blue-100 p-4 rounded-l-md border border-gray-300">
              <textarea
                className="border border-gray-300 rounded-md p-2 w-full"
                value={item.text}
                onChange={(e) => handleTextChange(index, e.target.value)}
              />
              <div className="flex justify-end mt-2">
                <button
                  className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  onClick={async () => await sendOnTxt(index)}
                >
                  Convert Text
                </button>
              </div>
            </div>

            {/* Right Section */}
            <div className="bg-red-100 p-4 rounded-r-md border border-gray-300">
              <p>{item.types}</p>
              <div className="flex justify-end mt-2">
                {index !== 0 && (
                  <button
                    className="bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mr-2"
                    onClick={() => removeText(index)}
                  >
                    -
                  </button>
                )}
                <button
                  className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  onClick={addText}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DescribeText;