import React, { useRef } from 'react';

const Home: React.FC = () => {
  return (
    <div className="p-4"> 
        <h2>Bienvenue sur mon site !</h2>
        <p>Ceci est une application React avec TypeScript.</p>
    </div>
  );
}

export default Home