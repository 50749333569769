import React, { useState } from 'react';
import { options } from '../config';

const DescribeText: React.FC = () => {
  return (
    <>
    </>
  );
};

export default DescribeText;